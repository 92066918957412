import { useQuery } from '@tanstack/vue-query'
import { QueryName } from '@/vars/QueryAttr'
import ConfigurationApi from '@/libs/api/Configuration.api'
import { computed, ref, watch } from 'vue'
import { StorageKey } from '@/vars/StorageAttr'

// Ref globale à toutes les utilisations de `useWhatsNew()`
const hasNewProductUpdatesToShow = ref(false)

export default function useWhatsNew() {
    const { data: productUpdates } = useQuery({
        queryKey: [QueryName.PRODUCT_UPDATES],
        queryFn: () => ConfigurationApi.getLastProductUpdates()
    })

    const newProductUpdateList = computed(() => {
        const lastSeenProductDate = window.localStorage.getItem(StorageKey.PRODUCT_UPDATE_LAST_DATE)

        if (!productUpdates.value?.data) {
            return []
        }

        if (!lastSeenProductDate) {
            return productUpdates.value.data
        }

        return productUpdates.value.data.filter(
            (productUpdate) => new Date(productUpdate.date).getTime() > new Date(lastSeenProductDate).getTime()
        )
    })

    watch(
        newProductUpdateList,
        () => {
            hasNewProductUpdatesToShow.value = !!newProductUpdateList.value.length
        },
        { immediate: true }
    )

    function markProductUpdateAsRead() {
        window.localStorage.setItem(StorageKey.PRODUCT_UPDATE_LAST_DATE, new Date().toISOString())
        hasNewProductUpdatesToShow.value = false
    }

    return {
        productUpdates,
        hasNewProductUpdatesToShow,
        markProductUpdateAsRead
    }
}
