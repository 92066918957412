import { Meta, ResponseV3 } from '@/types/API.type'
import { MemberAdminDetails } from '@/types/response/members-api/MemberAdminDetails.type'
import {
    Member,
    MemberExperienceForm,
    MemberFollow,
    OnboardingConfiguration,
    ProOnboardingConfiguration,
    UpdateMember,
    MemberSearch,
    MemberBusinessCenters,
    Goal,
    BusinessIssues,
    MemberLight,
    MemberEventShort,
    MemberSearchFilters,
    MemberWithSameEvent,
    MemberActivityStats,
    AdditionalAdminInformations,
    SuggestedPartners,
    SuggestedMembers,
    Score
} from '@/types/Member.type'
import Http from './ApiClient'
import qs from 'qs'
import { ClubState } from '@/types/response/members-api/ClubState.type'
import { MetaPager } from '@/types/response/ResponseV3.type'
import { SearchMembersResponse } from '@/types/response/members-api/SearchMembers.type'
import { SearchContactsResponse } from '@/types/response/members-api/SearchContacts.type'
import { MemberSearchFiltersForm } from '@/types/forms/member/MemberSearchFilters.type'
import { SmartAction } from '@/types/response/members-api/SmartActions.type'
import { UpsellStakesForm } from '@/types/forms/member/UpsellStakesForm'
import { GetStartedTodo } from '@/types/response/members-api/GetStartedTodo.type'
import { LinkedinProfileResponse } from '@/types/response/members-api/LinkedinProfiles.type'
import { ImportLinkedinProfileForm } from '@/types/forms/member/ImportLinkedinProfile.type'

export default class MemberApi {
    static async getLastLeClubMembers(): Promise<ResponseV3<Member[], null>> {
        const response = await Http.get('/v3/community-homepage/members/last')

        return response.data
    }

    static async getClubState(): Promise<ResponseV3<ClubState>> {
        const response = await Http.get(`/v3/members/me/state/club`)

        return response.data
    }

    static async getAdminMemberDetails(memberId: string): Promise<ResponseV3<MemberAdminDetails, null>> {
        const response = await Http.get(`/v3/admin/members/${memberId}`)

        return response.data
    }

    static async getCheckIsCompletedProfile(context: 'job' | 'event' | 'company'): Promise<boolean> {
        const response = await Http.get('/v3/members/completed-profile?context=' + context)
        if (response.status === 200) {
            return true
        } else {
            return false
        }
    }

    static async getMember(memberId: string): Promise<ResponseV3<Member, undefined>> {
        const response = await Http.get(`/v3/members/${memberId}`)

        return response.data
    }

    static async getPublicMemberList(
        memberIdListSeparatedByCommas: string
    ): Promise<ResponseV3<MemberLight[], undefined>> {
        const response = await Http.get(`/v3/members/light`, {
            params: {
                ids: memberIdListSeparatedByCommas
            }
        })

        return response.data
    }

    static async getMemberList(memberIds: string[]): Promise<Member[]> {
        return await Promise.all(
            memberIds.map(async (id) => {
                const response = await MemberApi.getMember(id)

                return response.data
            })
        )
    }

    static async getMemberLightList(memberIds: string[]): Promise<MemberLight[]> {
        const response = await Http.get(`/v3/members/light/`, {
            params: {
                ids: memberIds.join(',')
            }
        })
        return response.data.data
    }

    static async getMembers(params, search?: string): Promise<ResponseV3<Member[], Meta>> {
        const response = await Http.post(
            'v3/search/members',
            { search: search && search !== '' ? [search] : undefined },
            {
                params: {
                    sort: 'scoring',
                    ...params
                }
            }
        )

        return response.data
    }

    static async getOnboardingConfiguration(): Promise<ResponseV3<OnboardingConfiguration, undefined>> {
        const response = await Http.get('v3/onboarding/configuration')

        return response.data
    }

    static async getProOnboardingConfiguration(): Promise<ResponseV3<ProOnboardingConfiguration, undefined>> {
        const response = await Http.get('v3/teams/configuration')

        return response.data
    }

    static async updateMember(options: UpdateMember): Promise<void> {
        await Http.patch('v3/members/me', options)
    }

    static async updateAvatar(file: string): Promise<void> {
        await Http.post('v3/members/me/picture', { file })
    }

    static async updatePassword(plainPassword: string): Promise<void> {
        await Http.post('v3/members/me/password', { plainPassword })
    }

    static async followMember(id: string) {
        await Http.post(`/v3/members/${id}/like`)
    }
    static async unfollowMember(id: string) {
        await Http.delete(`/v3/members/${id}/like`)
    }

    static async myFollowers(): Promise<ResponseV3<MemberFollow[], undefined>> {
        const response = await Http.get('v3/members/favorites')

        return response.data
    }

    static async createMemberExperience(experience: MemberExperienceForm) {
        await Http.post('v3/members/experiences', { ...experience })
    }

    static async deleteMemberExperience(experienceId: number) {
        await Http.delete(`v3/members/experiences/${experienceId}`)
    }

    static async updateMemberExperience(experienceId: number, experience: MemberExperienceForm) {
        await Http.patch(`v3/members/experiences/${experienceId}`, {
            ...experience
        })
    }

    static async searchMembers(search: string, size = 20): Promise<ResponseV3<MemberSearch[], undefined>> {
        const response = await Http.get(
            `${import.meta.env.VITE_PRIVATE_BASE_URL}/v2/search/users?search=${search}&size=${size}`
        )

        return response.data
    }
    static async setOnboardingProfile({ shortDescription, pictureFile }) {
        const form = new FormData()

        form.append('shortDescription', shortDescription)
        form.append('pictureFile', pictureFile)

        const response = await Http.post(`${import.meta.env.VITE_PRIVATE_BASE_URL}/member/onboarding/set-profile`, form)
        return response.data
    }

    static async getBusinessCenters(): Promise<MemberBusinessCenters[]> {
        const response = await Http.get(`${import.meta.env.VITE_PRIVATE_BASE_URL}/member/business-center/availables`)
        return response.data
    }

    static async setOnboardingPreferences({
        businessCenter,
        memberBusinessCenters,
        relationshipPreferences,
        communicationPreferences
    }) {
        const response = await Http.post(`${import.meta.env.VITE_PRIVATE_BASE_URL}/member/onboarding/set-preferences`, {
            businessCenter,
            memberBusinessCenters,
            relationshipPreferences,
            communicationPreferences
        })
        return response.data
    }

    static async setPreferencesV2({
        businessCenter,
        memberBusinessCenters,
        relationshipPreferences,
        communicationPreferences
    }) {
        const response = await Http.post(
            `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/onboarding/set-preferences`,
            qs.stringify({ businessCenter, memberBusinessCenters, relationshipPreferences, communicationPreferences })
        )
        return response.data
    }

    static async setOnboardingPassword(password: string) {
        const response = await Http.post(`${import.meta.env.VITE_PRIVATE_BASE_URL}/member/onboarding/set-password`, {
            plainPassword: password
        })

        return response.data
    }

    static async getInterestList(): Promise<{ id: number; name: string }[]> {
        const response = await Http.get(`${import.meta.env.VITE_PRIVATE_BASE_URL}/member/interest/availables`)
        return response.data
    }

    static async postInterestsList(labels: number[] | undefined) {
        const response = await Http.post(
            `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/onboarding/set-interests`,
            qs.stringify({ labels })
        )

        return response.data
    }

    static async getGoalList(): Promise<Goal[]> {
        const response = await Http.get('/member/goal/availables')
        return response.data
    }

    static async postOnboardingGoals({ goals, other }) {
        const response = await Http.post(
            `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/onboarding/set-goals`,
            qs.stringify({
                goals: [...goals.map((goal) => ({ goal })), { other }]
            })
        )
        return response.data
    }

    static async addExperience({ company, position, currentPosition, startDate, endDate, description }) {
        const response = await Http.post(`${import.meta.env.VITE_PRIVATE_BASE_URL}/member/onboarding/add-experience`, {
            company,
            position,
            currentPosition,
            startDate,
            endDate,
            description
        })
        return response.data
    }

    static async getBusinessChallenges(): Promise<BusinessIssues[]> {
        const response = await Http.get(`${import.meta.env.VITE_PRIVATE_BASE_URL}/member/business-category/availables`)
        return response.data
    }

    static async postBusinessChallenges({ businessChallenges, other }) {
        const response = await Http.post(
            `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/onboarding/set-business-challenges`,
            qs.stringify({
                businessChallenges: [...businessChallenges.map((challenge) => ({ challenge })), { other }]
            })
        )
        return response.data
    }

    static async getMemberEvents(
        memberId: string,
        type: 'next' | 'recommended' | 'past',
        params?: { page: number; limit: number }
    ): Promise<ResponseV3<MemberEventShort[], MetaPager>> {
        const response = await Http.get(`/v3/members/${memberId}/events/${type}`, {
            params
        })
        return response.data
    }

    static async sendSuggestions(memberIdff: string, { members, message, timezone, scheduledAt }) {
        const form = new FormData()

        members.forEach((memberId: string, i: number) => form.append(`members[${i}]`, memberId))

        form.append('message', message)
        form.append('timezone', timezone)
        form.append('scheduledAt', scheduledAt)

        const response = await Http.post(
            `${import.meta.env.VITE_PRIVATE_BASE_URL}/member/${memberIdff}/recommendation/send`,
            form
        )
        return response.data
    }
    static async getMemberSearchFilters(filters: MemberSearchFiltersForm): Promise<MemberSearchFilters[]> {
        const members = await Http.post(`/v2/search/filters/members`, filters)
        return members.data
    }

    static async getMemberSearchAutocomplete(search: string): Promise<MemberSearchFilters[]> {
        const members = await Http.get('/v2/search/autocomplete/filters/members', {
            params: {
                search
            }
        })
        return members.data
    }

    static async getSearchMembers(params: {
        pageParam: number
        selectedOptions?: 'scoring' | 'lastName' | 'newest'
        filters?: MemberSearchFiltersForm
    }): Promise<SearchMembersResponse> {
        const response = await Http.post('/v2/search/members', params.filters, {
            params: {
                page: params.pageParam,
                sort: params.selectedOptions
            }
        })
        return response.data
    }

    static async getSearchContacts(params: {
        pageParam: number
        selectedOptions?: 'scoring' | 'lastName' | 'newest'
    }): Promise<SearchContactsResponse> {
        const response = await Http.post('/v2/search/my-contacts', undefined, {
            params: {
                page: params.pageParam,
                sort: params.selectedOptions
            }
        })
        return response.data
    }

    static async getSearchFavorites(params: {
        pageParam: number
        selectedOptions?: 'scoring' | 'lastName' | 'newest'
    }): Promise<SearchContactsResponse> {
        const response = await Http.post('/v2/search/my-favorites', undefined, {
            params: {
                page: params.pageParam,
                sort: params.selectedOptions
            }
        })
        return response.data
    }

    static async getMembersWithSameEvent(): Promise<MemberWithSameEvent[]> {
        const members = await Http.get(`/v2/search/members-with-same-events`)
        return members.data
    }

    static async getMemberActivityStats(memberId: string): Promise<ResponseV3<MemberActivityStats>> {
        const response = await Http.get(`/v3/admin/members/${memberId}/activity/stats`)

        return response.data
    }
    static async getSuggestedPartners(memberId: string): Promise<ResponseV3<SuggestedPartners[]>> {
        const members = await Http.get(`/v3/members/${memberId}/suggestions/partners`)

        return members.data
    }

    static async algoSuggestionPing(firstMemberId: string, secondMemberId: string) {
        await Http.post(`/v3/members/suggestion/ping/${firstMemberId}/${secondMemberId}`)
    }

    static async getAdditionalAdminInformations(memberId: string): Promise<AdditionalAdminInformations> {
        const response = await Http.get(`/members/${memberId}/admin/additional/informations`)
        return response.data
    }

    static async getMemberScore(memberId: string, interval: 'semester' | 'quarter' | ''): Promise<Score> {
        if (interval) {
            const response = await Http.get(`/member/${memberId}/scoring/${interval}`)
            return response.data
        } else {
            const response = await Http.get(`/member/${memberId}/scoring`)
            return response.data
        }
    }

    static async getMemberSuggestions(
        memberId: string,
        suggestionType: 'members' | 'peers' | 'mutual-interest'
    ): Promise<ResponseV3<SuggestedMembers[]>> {
        const members = await Http.get(`/v3/members/${memberId}/suggestions/${suggestionType}`)

        return members.data
    }

    /**
     * Retourne la liste des actions rapides à effectuer par l'utilisateur. Ces actions
     * mettent en avant des actions que l'on veut faire effectuer à l'utilisateur.
     *
     * (Utilisé dans le network)
     */
    static async getSmartActions(): Promise<ResponseV3<SmartAction[], { refetchAfter?: number }>> {
        const members = await Http.get(`/v3/onboarding/smart-actions`)
        return members.data
    }

    static async sendUpsellStakes(form: UpsellStakesForm) {
        await Http.post('/v3/onboarding/upsell-stakes', form)
    }

    static async getLinkedinProfile(): Promise<ResponseV3<LinkedinProfileResponse>> {
        const response = await Http.get('/v3/onboarding/linkedin-profile')
        return response.data
    }

    static async importLinkedinProfile(form: ImportLinkedinProfileForm): Promise<ResponseV3<LinkedinProfileResponse>> {
        const response = await Http.patch('/v3/onboarding/linkedin-profile', form)
        return response.data
    }

    static async getStartedTodo(): Promise<ResponseV3<GetStartedTodo[]>> {
        const response = await Http.get('/v3/todo/get-started')
        return response.data
    }

    static async markGetStartedActionAsDone(
        taskId: string,
        form: { status: 'todo' | 'done' | 'in_progress' }
    ): Promise<void> {
        const response = await Http.patch(`/v3/todo/get-started/${taskId}`, form)
        return response.data
    }
}
