<script setup lang="ts">
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import CardBody from '@/components/ui/wrappers/cards/CardBody.vue'
    import { useI18n } from 'vue-i18n'
    import { useMutation, useQueryClient } from '@tanstack/vue-query'
    import { QueryName } from '@/vars/QueryAttr'
    import MemberApi from '@/libs/api/Member.api'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import { GetStartedTodo } from '@/types/response/members-api/GetStartedTodo.type'
    import { AxiosError } from 'axios'
    import { computed, ref, watch } from 'vue'
    import LottieView from '@/components/ui/wrappers/LottieView.vue'
    import useGettingStartedTodoQuery from '@/libs/queries/useGettingStartedTodoQuery'
    import { StorageKey } from '@/vars/StorageAttr'
    import { useLocalStorage } from '@vueuse/core'

    const props = defineProps<{
        compact?: boolean
    }>()

    const { t } = useI18n()
    const queryClient = useQueryClient()
    const hasFinishedGetStarted = useLocalStorage(StorageKey.FINISHED_GET_STARTED, 'false')

    const isProductionBuild = import.meta.env.NODE_ENV === 'production'

    const { data: todo, refetch: refetchTasks } = useGettingStartedTodoQuery({ refetchOnMount: true })

    const { mutate: markTaskAsDone } = useMutation<unknown, AxiosError, Pick<GetStartedTodo, 'id'>>({
        mutationFn: (task) => MemberApi.markGetStartedActionAsDone(task.id, { status: 'done' }),
        onMutate: (task) => {
            const newTodoList = { ...todo.value }

            if (newTodoList.data) {
                newTodoList.data = newTodoList.data.map((todo) => ({
                    ...todo,
                    status: todo.id === task.id ? 'done' : todo.status
                }))
            }

            queryClient.setQueryData([QueryName.GETTING_STARTED], newTodoList)
        }
    })

    const triggerAnimation = ref(false)

    const displayedTodo = computed(() => {
        if (!todo.value?.data) {
            return []
        }

        const order = {
            todo: 1,
            in_progress: 2,
            done: 3
        }

        return todo.value.data.toSorted((a, b) => {
            return order[a.status] - order[b.status]
        })
    })

    watch(
        todo,
        () => {
            const allTasksAreDone = todo.value?.data.every((task) => task.status === 'done')

            triggerAnimation.value = Boolean(todo.value?.data.length && allTasksAreDone && !props.compact)
        },
        { immediate: true, deep: true }
    )

    const onCompletedConfettiAnimation = () => {
        triggerAnimation.value = false
        hasFinishedGetStarted.value = 'true'
    }

    const onEnterCard = () => {
        if (todo.value?.data.every((action) => action.status === 'done')) {
            return null
        }

        refetchTasks()
    }
</script>

<template>
    <Transition class="started" name="fade" :appear="compact">
        <div v-if="todo?.data?.length">
            <h2 v-if="!compact" class="title-3 mb-4">{{ t('compact.first_actions') }}</h2>
            <CardWrapper tag="section" class="relative" @mouseenter="onEnterCard">
                <template v-if="triggerAnimation && hasFinishedGetStarted === 'false'">
                    <LottieView
                        path="/lottie/confetti.json"
                        autoplay
                        class="started__tada"
                        @completed="onCompletedConfettiAnimation"
                    />
                    <audio v-if="!isProductionBuild" autoplay>
                        <source src="/sound/party_horn.mp3" />
                    </audio>
                </template>
                <CardBody :class="{ '!px-4r !py-4r': compact }">
                    <h2 class="mb-4 text-base font-medium">
                        {{ compact ? t('compact.first_actions') : t('first_actions') }}
                    </h2>
                    <div class="flex flex-col gap-2">
                        <TransitionGroup name="list">
                            <a
                                v-for="task in displayedTodo"
                                :key="task.cta.title"
                                :href="task.cta.url"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="flex cursor-pointer items-start rounded text-sm transition-all duration-300 hover:translate-x-0.5"
                                :class="{
                                    'bg-go-10': task.status === 'done',
                                    'bg-flagship-10': task.status !== 'done',
                                    'gap-1 p-2': compact,
                                    'gap-3 p-4': !compact
                                }"
                            >
                                <MaterialIcon
                                    v-tooltip.bottom="{ content: task.status !== 'done' ? t('mark_as_done') : '' }"
                                    class="-mx-1 px-1 text-base"
                                    :name="task.status === 'done' ? 'task_alt' : 'radio_button_unchecked'"
                                    :class="{
                                        'text-go': task.status === 'done',
                                        'text-royale': task.status !== 'done'
                                    }"
                                    @click.prevent="markTaskAsDone(task)"
                                />
                                <span
                                    class="block flex-1"
                                    :class="{
                                        'text-go line-through ': task.status === 'done',
                                        'text-royale': task.status !== 'done'
                                    }"
                                >
                                    {{ task.cta.title }}
                                </span>
                                <MaterialIcon
                                    v-if="task.status !== 'done' && !compact"
                                    name="arrow_forward"
                                    class="text-2xl text-royale"
                                />
                            </a>
                        </TransitionGroup>
                    </div>
                </CardBody>
            </CardWrapper>
        </div>
    </Transition>
</template>

<style lang="scss" scoped>
    .started {
        &__tada {
            @apply pointer-events-none fixed inset-0 z-50;

            :deep(svg) {
                width: auto !important;
            }
        }
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: all 350ms ease-in-out;
        }

        &-enter-from,
        &-leave-to {
            opacity: 0;
            translate: 0 20%;
        }
    }

    /* apply transition to moving elements */
    .list-move {
        transition: all 0.5s ease;
    }

    /* ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly. */
    .list-leave-active {
        position: absolute;
    }
</style>

<i18n lang="json">
{
    "fr": {
        "compact": {
            "first_actions": "Vos premières actions"
        },
        "first_actions": "D’après vos objectifs, voici quelques services sur lequels nous pourrions vous aider :",
        "dont_show": "Ne plus afficher",
        "mark_as_done": "Marquer comme terminé"
    },
    "en": {
        "compact": {
            "first_actions": "Your first actions"
        },
        "first_actions": "According to your objectives, here are a some of the services we can offer you:",
        "dont_show": "Ne plus afficher",
        "mark_as_done": "Mark as done"
    }
}
</i18n>
