<script setup lang="ts">
    import { computed, provide } from 'vue'
    import { useRoute } from 'vue-router'
    import { VueQueryDevtools } from '@tanstack/vue-query-devtools'

    import AsideLayout from './components/layouts/network/AsideLayout/AsideLayout.vue'
    import DefaultLayout from './components/layouts/DefaultLayout.vue'
    import EmptyLayout from './components/layouts/EmptyLayout.vue'
    import DefaultClubLayout from './components/layouts/leclub/DefaultClubLayout.vue'
    import ProDefaultLayout from './components/layouts/pro/DefaultLayout.vue'
    import PendingDeploymentModal from './components/partials/debugs/PendingDeploymentModal.vue'
    import DialogToast from './components/ui/dialogs/DialogToast.vue'
    import { SpaName } from './vars/SpaAttr'
    import { ThemeName } from './vars/ThemeAttr'
    import ThemeLayer from './components/ui/layers/ThemeLayer.vue'
    import SwitchToBar from '@/components/partials/common/SwitchToBar.vue'
    import useNewAppReleaseDetector from './libs/compositions/useNewAppReleaseDetector'
    import ModalFeedBack from './components/partials/club/modals/feedback/ModalFeedBack.vue'
    import { useAuthStore } from './libs/stores/auth.store'
    import CookiesConsent from '@/components/partials/common/CookiesConsent.vue'
    import ModalShortLostWarning from '@/components/partials/club/modals/ModalShortLostWarning.vue'
    import WhatsNew from './components/partials/common/WhatsNew/WhatsNew.vue'

    const route = useRoute()
    const authStore = useAuthStore()
    useNewAppReleaseDetector()

    const spaRunning = import.meta.env.VITE_SPA_RUNNING
    const activateQueryDevTool = computed(() => import.meta.env.VITE_QUERY_DEV_TOOL === 'true')

    provide('themeName', ThemeName.MONDRIAN)

    function getDefaultLayout() {
        switch (import.meta.env.VITE_SPA_RUNNING) {
            case SpaName.LENETWORK:
                return authStore.isLoggedIn ? AsideLayout : DefaultLayout
            case SpaName.LECLUB:
                return DefaultClubLayout
            case SpaName.PRO:
                return ProDefaultLayout
            default:
                return DefaultLayout
        }
    }

    const layoutComponent = computed(() => {
        if (route.name === null) return EmptyLayout

        return route.meta.layout || getDefaultLayout()
    })

    const isSpaClubRunning = computed(() => {
        return import.meta.env.VITE_SPA_RUNNING === SpaName.LECLUB
    })

    const showModalFeedback = computed(() => {
        return authStore.isLoggedIn && isSpaClubRunning.value && !authStore.isLostClub && !authStore.isOnSwitchTo
    })

    const scope = computed(() => import.meta.env.VITE_SPA_RUNNING)
</script>

<template>
    <ThemeLayer>
        <SwitchToBar />
        <ModalShortLostWarning v-if="[SpaName.LENETWORK, SpaName.LECLUB].includes(spaRunning)" />
        <Component :is="layoutComponent" />
        <!-- Ne pas mettre de "em" pour la width, sinon ça ne se centre pas -->
        <!-- eslint-disable-next-line   -->
        <notifications classes="custom-notification" position="center bottom" width="360px" :max="3" ignoreDuplicates>
            <template #body="props">
                <DialogToast
                    :title="props.item.title"
                    :text="props.item.text"
                    :type="props.item.type"
                    :action="props.item.data.action"
                    :close="props.close"
                />
            </template>
        </notifications>
        <ModalFeedBack v-if="showModalFeedback" />
        <CookiesConsent />
        <PendingDeploymentModal />
        <WhatsNew v-if="authStore.isLoggedIn && spaRunning === SpaName.LECLUB" :scope="scope" />
        <VueQueryDevtools v-if="activateQueryDevTool" />
    </ThemeLayer>
</template>

<style lang="scss">
    body > .OT_subscriber {
        display: none;
    }
</style>
